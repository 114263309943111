<script>
import { computed, ref } from 'vue'
import {
  mobileMenuButtons,
  mobileMenuButtonsDoctor
} from '../../../constants/mobileMenuButtons'
import MenuSmallTile from './MenuSmallTile.vue'
import MenuButtonList from '@/components/pages/menu/MenuButtonList.vue'
import MenuWideTile from './MenuWideTile.vue'
import BottomDrawer from '@/components/modals/BottomDrawer.vue'
export default {
  name: 'MenuTilesList',
  components: { MenuSmallTile, MenuWideTile, BottomDrawer, MenuButtonList },
  setup () {
    const isDrawerOpen = ref(false)
    const drawerContent = ref('')

    const openDrawer = (tileName) => {
      isDrawerOpen.value = true
      drawerContent.value = tileName
    }

    const drawerTitle = computed(() => {
      if (drawerContent.value === 'Запись к врачу') {
        return { title: 'Запись к врачу', subtitle: 'Любым удобным способом' }
      } else {
        return {}
      }
    })

    return {
      mobileMenuButtonsDoctor,
      mobileMenuButtons,
      drawerContent,
      isDrawerOpen,
      drawerTitle,
      openDrawer
    }
  }
}
</script>

<template>
  <div class="tiles-wrapper">
    <template v-for="tile in mobileMenuButtons" :key="tile.smallIconName">
      <MenuWideTile
        v-if="tile.isWide"
        @openDrawer="openDrawer"
        :to="tile.to"
        :smallIconName="tile.smallIconName"
        :bgColor="tile.bgColor"
        :title="tile.title"
        :subtitle="tile.subtitle"
        :bigIconName="tile.bigIconName" />

      <MenuSmallTile
        v-if="!tile.isWide"
        :to="tile.to"
        :smallIconName="tile.smallIconName"
        :bgColor="tile.bgColor"
        :title="tile.title"
        :subtitle="tile.subtitle" />
    </template>

    <BottomDrawer
      @closeDrawer="isDrawerOpen = false"
      :isDrawerOpen="isDrawerOpen"
      :title="drawerTitle.title"
      :subtitle="drawerTitle.subtitle">
      <div v-if="drawerContent === 'Запись к врачу'">
        <MenuButtonList
          class="appointment-menu__buttons"
          :buttons="mobileMenuButtonsDoctor" />
      </div>
    </BottomDrawer>
  </div>
</template>

<style lang="scss" scoped>
.tiles-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 24px;
}

.appointment-menu__buttons {
  margin-bottom: 0;
}
</style>
