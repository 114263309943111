<script>
import SectionWrapperNew from '../components/ui/SectionWrapperNew.vue'
import ArrowTitleNew from '../components/ui/typography/ArrowTitleNew.vue'
import MenuTilesList from '../components/pages/menu/MenuTilesList.vue'
import MenuButtonList from '../components/pages/menu/MenuButtonList.vue'
import MenuBottom from '../components/pages/menu/MenuBottom.vue'
import { mobileMenuButtonsApp } from '@/constants/mobileMenuButtons.js'
export default {
  name: 'MainMenuView',
  components: { SectionWrapperNew, ArrowTitleNew, MenuTilesList, MenuButtonList, MenuBottom },
  setup () {
    return { mobileMenuButtonsApp }
  }
}
</script>

<template>
  <div class="test">
    <ArrowTitleNew title="Еще" />
    <SectionWrapperNew>

      <MenuTilesList />

      <MenuButtonList :buttons="mobileMenuButtonsApp"/>

      <MenuBottom />

    </SectionWrapperNew>
  </div>
</template>

<style lang="scss" scoped>
.test {
  position: relative;
  display: block;
  max-height: calc(100vh - 78px);
  overflow-y: auto;
  background: #fff;
}

.sub {
  position: sticky;
  top: 0;
}

</style>
