<script>
import { useAppInfoStore } from '@/stores/AppInfoStore'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

export default {
  name: 'MenuSmallTile',
  props: {
    smallIconName: {
      type: String,
      default: '',
      required: true
    },
    title: {
      type: String,
      default: '',
      required: true
    },
    subtitle: {
      type: String,
      default: '',
      required: true
    },
    bgColor: {
      type: String,
      default: '#EEF1F3',
      required: false
    },
    to: {
      type: String,
      default: '',
      required: true
    }
  },
  setup (props) {
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const appStore = useAppInfoStore()
    const { appInfo } = storeToRefs(appStore)

    const isNotHidden = computed(() => {
      if (props.title === 'Чат с пациентом') {
        if (appInfo.value.is_doctor_chat && user.value?.is_doctor) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })

    const href = computed(() => {
      if (props.smallIconName === 'address-simple-icon') return 'https://www.k31.ru/contacts/'
      if (props.smallIconName === 'whatsapp-new-icon') return 'https://api.whatsapp.com/send?phone=79162591131'
      else return ''
    })

    return { isNotHidden, href }
  }
}
</script>

<template>
  <component
    v-if="isNotHidden"
    :is="to ? 'router-link' : 'a'"
    :to="to"
    :href="href"
    :target="!to ? '_blank' : null"
    class="small-tile__wrapper">
    <div class="small-tile__small-icon">
      <img :src="require(`@/assets/uploads/icon/common/${smallIconName}.svg` )" :alt="smallIconName">
    </div>

    <p class="small-tile__title">{{ title }}</p>

    <p class="small-tile__subtitle">{{ subtitle }}</p>
  </component>
</template>

<style lang="scss" scoped>
.small-tile__wrapper {
  border-radius: 12px;
  padding: 16px;
  background: v-bind(bgColor);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.small-tile__small-icon {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.small-tile__title {
  max-width: 116px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #002856;
  margin-bottom: 4px;
}

.small-tile__subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #002856;
}
</style>
