<template>
  <div class="finger">
    <img src="@/assets/uploads/images/header/logo.svg" alt="Логотип к31" />
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import { onMounted } from 'vue'
import { deleteCookieToken } from '@/utilits/deleteCookieToken'
import { useBiometry } from '@/composables/useBiometry'
import { useNotifyStore } from '@/stores/NotifyStore'
import { storeToRefs } from 'pinia'
import { showToast } from '@/utilits/showToast'

export default {
  name: 'BiometricValidationView',

  setup () {
    const {
      isBiometryAvailable,
      isBiometryActive,
      setBiometry,
      validateBiometricAccess,
      getTokenFromBiometry
    } = useBiometry()
    const notifyStore = useNotifyStore()
    const { fromBackground } = storeToRefs(notifyStore)
    const router = useRouter()
    const userStore = useUserStore()
    const { setUserToken, setUser } = userStore

    onMounted(async () => {
      validateBiometricAccess()
      setBiometry(JSON.parse(window.localStorage.getItem('is_biometry_active')))
      if (isBiometryAvailable.value && isBiometryActive.value) {
        try {
          const token = await getTokenFromBiometry()
          setUserToken(token)
          await setUser(token)
          if (fromBackground.value) {
            return
          }
          router.push({
            name: 'home'
          })
          showToast({ title: 'Добро пожаловать', text: '', icon: 'success' })
        } catch {
          deleteCookieToken()
          router.push({
            name: 'auth-phone'
          })
        }
      } else {
        deleteCookieToken()
        router.push({
          name: 'auth-phone'
        })
      }
    })
  }
}
</script>

<style scoped lang="scss">
.finger {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 100vh;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    box-shadow: 1px 1px 2.5rem 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 20px;
    background: #ffffff;
  }
}
</style>
