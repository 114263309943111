<template>
  <section class="login">
    <div class="container">
      <div class="login__logo">
        <router-link to="/">
        <img src="@/assets/uploads/icon/common/logo-white.svg" alt="логотип к31">
        </router-link>
      </div>
      <div class="login__logo login__logo--desktop">
        <router-link to="/">
          <img src="@/assets/uploads/images/header/logo-blue.svg" alt="логотип к31">
        </router-link>
      </div>

      <div class="login__container">
        <router-view></router-view>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'AuthView'
}
</script>

<style scoped lang="scss">
.login {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  min-height: 100vh;

  &__container {
    min-height: 259px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    box-shadow: 1px 1px 2.5rem 1px rgba(0, 0, 0, .1);
    padding: 20px;
    border-radius: 6px;
    background: rgba(0, 40, 86, 0);
    @include desktop {
      margin: 0 auto;
      max-width: 800px;
      padding: 48px;
    }
  }

  &__logo {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    @include desktop {
      display: none;
    }
    &.login__logo--desktop {
      display: none;
      @include desktop {
        display: flex;
        img {
          width: 100px;
        }
      }
    }
  }
}

.login__container:has(.pin) {
  padding-top: 5px;
  box-shadow: none;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login__container:has(.phone) {
  background: #FFF;
}
.login__container:has(.sms) {
  background: #FFF;
}
.login__container:has(.gosuslugi) {
  background: #FFF;
}
</style>
