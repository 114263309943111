<template>
  <ArrowTitle title="Программы" :is-app="isApp"></ArrowTitle>
  <SectionWrapper stretched>
    <div class="programs">
      <h1 v-if="currentStage === 1" class="program-selector__title">
        Ответьте на несколько вопросов, а мы подберем вам индивидуальную
        программу в режиме онлайн
      </h1>

      <ScheduleStages :currentStage="currentStage" :isProgram="true" />

      <ProgramCheckUpForm
        v-if="currentStage === 1"
        :typeId="typeId"
        :programAssignment="programAssignment"
        @assignProgram="changeAssignment"
        @selectTypeId="typeId = $event"
        :programGroups="programGroups"
        @updateSelectedPrograms="programGroups = $event"
        @setAge="age = $event"
        @setGender="genderId = $event"
        :loading="loading"
        @goToNextStage="goToNextStage" />

      <ProgramCheckUpSignIUp
        v-else
        :selectedProgramGroups="selectedProgramGroups"
        @checkupSignup="postProgramSelection"
        @resetCheckup="reset"
        @resetError="error = null" />

      <ErrorText
        v-if="error"
        textAlign="center"
        :error="error"
        :showBtn="false"
        class="error-block" />

      <ModalBlueBgWrapper :isModalOpen="isSuccessModalOpen" noCloseBtn>
        <div class="modal-success-message">
          <h1>Ваш запрос на чек-ап отправлен специалистам в клинику К+31</h1>
          <p>
            В ближайшее время с вами свяжется колл-центр для подбора удобного
            времени прохождения или консультации
          </p>
          <CustomButton transparent @click.prevent="closeModal">
            Понятно
          </CustomButton>
        </div>
      </ModalBlueBgWrapper>
    </div>
  </SectionWrapper>
</template>

<script>
import { computed, onDeactivated, ref, watchEffect } from 'vue'
import { getAge } from '@/utilits/formatDateFunctions'
import { getProgramsCheckup } from '@/api/programs/getProgramsCheckup'
import { useUserStore } from '@/stores/UserStore'
import { useRouter } from 'vue-router'
import { postProgramCheckup } from '@/api/programs/postProgramCheckup'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import ErrorText from '@/components/ui/ErrorText'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ModalBlueBgWrapper from '@/components/modals/ModalBlueBgWrapper.vue'
import ProgramCheckUpSignIUp from '@/components/pages/programs/ProgramCheckUpSignIUp.vue'
import ProgramCheckUpForm from '@/components/pages/programs/ProgramCheckUpForm.vue'
import ScheduleStages from '@/components/pages/online-appointment/ScheduleStages.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'

export default {
  name: 'ProgramsSelectionView',
  components: {
    ScheduleStages,
    ArrowTitle,
    SectionWrapper,
    ProgramCheckUpForm,
    ProgramCheckUpSignIUp,
    ModalBlueBgWrapper,
    CustomButton,
    ErrorText
  },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup () {
    const router = useRouter()
    const userStore = useUserStore()

    const loading = ref(false)
    const error = ref(null)
    const isSuccessModalOpen = ref(false)

    const currentStage = ref(1)

    const programAssignment = ref(null)
    const typeId = ref(null)
    const genderId = ref(null)
    const age = ref(null)

    const programsData = ref(null)
    const programGroups = ref(null)
    const selectedProgramGroups = computed(() => {
      if (programGroups.value && programsData.value) {
        return programsData.value.filter((p) =>
          programGroups.value.find((pg) => pg.id === p.id && pg.isSelected)
        )
      } else return null
    })

    const getProgramSelection = async () => {
      if (userStore.user) {
        loading.value = true
        programGroups.value = null
        programsData.value = null
        try {
          programsData.value = await getProgramsCheckup({
            genderId: genderId.value,
            typeId: typeId.value,
            age: age.value
          })
          programGroups.value = programsData.value.map((p) => ({
            title: p.title,
            id: p.id,
            isSelected: false
          }))
        } catch (err) {
          error.value = err
        } finally {
          loading.value = false
        }
      }
    }

    const postProgramSelection = async (checkup) => {
      if (!checkup.length) {
        error.value = 'Не выбрана ни одна программа'
        return
      }
      const checkupInfo = {
        genderId: genderId.value,
        typeId: typeId.value,
        age: age.value,
        checkup: checkup
      }
      loading.value = true
      console.log('checkupInfo', checkupInfo)
      try {
        await postProgramCheckup(checkupInfo)
        isSuccessModalOpen.value = true
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } catch (err) {
        error.value = err
      } finally {
        loading.value = false
      }
    }

    const changeAssignment = (assignmentId) => {
      if (userStore.user && assignmentId === 0) {
        genderId.value = userStore.user.gender_id
        age.value = getAge(userStore.user.birthday)
      }
      if (assignmentId === 1) {
        genderId.value = null
        age.value = null
        programGroups.value = null
        programsData.value = null
      }
      programAssignment.value = assignmentId
    }

    const goToNextStage = () => {
      if (selectedProgramGroups.value?.length) {
        currentStage.value = 2
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } else {
        error.value = 'Выберите пожелание'
      }
    }

    const closeModal = () => {
      isSuccessModalOpen.value = false
      reset()
      router.push({ name: 'program-all' })
    }

    const reset = () => {
      typeId.value = null
      genderId.value = null
      age.value = null
      programAssignment.value = null
      programGroups.value = null
      programsData.value = null
      loading.value = false
      currentStage.value = 1

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }

    watchEffect(async () => {
      error.value = null
      if (
        programAssignment.value !== null &&
        typeId.value &&
        genderId.value !== null &&
        age.value
      ) {
        await getProgramSelection()
      }
    })

    watchEffect(() => {
      // console.log('program assignment', programAssignment.value)
      // console.log('typeId.value', typeId.value)
      // console.log('programGroups', programGroups.value)
      // console.log('age value', age.value)
      // console.log('gender value', genderId.value)
      // console.log('error', error.value)
      // console.log('isSuccessModalOpen', isSuccessModalOpen.value)
    })

    onDeactivated(() => {
      reset()
    })
    return {
      changeAssignment,
      loading,
      error,
      programAssignment,
      typeId,
      genderId,
      age,
      currentStage,
      programsData,
      programGroups,
      goToNextStage,
      selectedProgramGroups,
      postProgramSelection,
      closeModal,
      isSuccessModalOpen,
      reset
    }
  }
}
</script>
<style scoped lang="scss">
.programs {
  min-height: 800px;
}
.btn-result {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #ffffff;
  background: #002856;
  text-transform: uppercase;
  width: 100%;
  padding: 14px;
  border-radius: 30px;
  border: none;
}
.programs__banner {
  margin-bottom: 18px;
}

.program-selector__title {
  padding: 0 5px;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.modal-success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 11px;

  h1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
</style>
