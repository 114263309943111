<template>
  <div
    class="modal fade modal-custom"
    :id="id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-custom__dialog">
      <div class="modal-content">
        <div class="modal-custom__header" :class="{ 'modal-header': title }">
          <h3 id="exampleModalLabel">{{ title }}</h3>
          <a
            v-if="showBtnClose"
            @click="closeBtnClicked"
            ref="closeBtn"
            type="button"
            class="btn-close modal-custom__close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></a>
        </div>
        <div class="modal-body modal-custom__body">
          <slot></slot>

          <div v-if="bottomBtns" class="modal__btns">
            <CustomButton
              @click="$emit('confirm')"
              white
              tag="button"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              small
              margin-bottom="0"
            >
              да
            </CustomButton>
            <CustomButton
              @click="$emit('cancelModal')"
              white
              tag="button"
              type="button"
              small
              data-bs-dismiss="modal"
              aria-label="Close"
              margin-bottom="0"
            >
              нет
            </CustomButton>
          </div>
        </div>
      </div>

      <slot name="outside"></slot>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'

export default {
  name: 'ModalBsWrapper',
  components: { CustomButton },
  emits: ['confirm', 'cancelModal'],
  props: {
    id: {
      type: String,
      required: true,
      default: 'modal'
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    showBtnClose: {
      type: Boolean,
      required: false,
      default: true
    },
    bottomBtns: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup (props, { expose }) {
    const closeBtn = ref(null)

    const closeModal = () => {
      console.log('2')
      closeBtn.value.dispatchEvent(new MouseEvent('click', { bubbles: false }))
    }
    const closeBtnClicked = () => {
      console.log('2')
    }
    expose({
      closeModal
    })

    return {
      closeBtn,
      closeBtnClicked
    }
  }
}
</script>

<style lang="scss">
.modal-custom:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 40, 86, 0.5);
  backdrop-filter: blur(1px);
}

.modal-custom__dialog {
  padding: 0 5px 0 5px;
  z-index: 1060;
  flex-direction: column;
  justify-content: center;
}

.modal-custom__header {
  position: relative;
  border-bottom: 0;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

.modal-custom__body {
  padding-top: 20px;
  margin-top: 0;
}

.modal-custom__close {
  position: absolute;
  right: 2px;
  top: -25px;
  filter: invert(100%) sepia(0%) saturate(7496%) hue-rotate(185deg)
    brightness(99%) contrast(97%);
  opacity: 1;
}

.modal__btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
</style>
