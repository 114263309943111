<template>
    <div class="pin">
        <TheLoader v-show="loading" :white="true" />
        <ErrorText
            class="pin-error"
            v-if="responseError"
            :error="responseErrorMessage"
            :show-btn="false"
          />
        <div v-if="!loading">
          <h3 class="pin__title">Вход по код-паролю</h3>
          <form
            action=""
            class="pin__form"
          >
              <OtpPinForm
                v-model="pin"
                :digit-count="6"
                :error="pinError"
                @is-completed="submitPin"
                :isAutofocus="true"
              />
              <div v-if="pinErrorMessage.error" class="error pin-help-block">
                {{ pinErrorMessage.msg }}
              </div>
              <div class="pin-help-block" @click="goToPhoneForm">
                Код утерян, отправить СМС для входа
              </div>
              <CellPhoneKeypad :isLogin="true" :pinLength="pin.length" class="pin-keypad" @change-pin="changePin"/>
              <button class="pin-help-block pin-skip-btn">
                <a
                  href="https://www.k31.ru/upload/doc/useterms.pdf"
                  target="_blank"
                >
                  Пользовательское соглашение
                </a>
              </button>
            <div v-if="platform !== 'web'" class="pin-help-block pin-version-info">
              <span v-if="platform === 'ios'">
                Версия приложения {{ appVersionIos }} {{ platform }}
              </span>
              <span v-if="platform === 'android'">
                Версия приложения {{ appVersionAndroid }} {{ platform }}
              </span>
            </div>
          </form>
      </div>
    </div>
</template>

<script>
import { onActivated, ref, onMounted } from 'vue'

import OtpPinForm from '@/components/pages/auth/OtpPinForm.vue'
import CellPhoneKeypad from '@/components/pages/auth/CellPhoneKeypad.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import ErrorText from '@/components/ui/ErrorText.vue'

import { showToast } from '@/utilits/showToast'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import { showFingerPrint } from '@/utilits/showFingerPrint'
import { storeToRefs } from 'pinia'
import { useBiometry } from '@/composables/useBiometry'
import { deleteCookieToken } from '@/utilits/deleteCookieToken'
import { getPlatform } from '@/utilits/getPlatform'
import { useNotifyStore } from '@/stores/NotifyStore'
import { loginUser } from '../../../api/authentication/loginUser'
import { getUserToken } from '@/api/authentication/getUserToken'

export default {
  name: 'PinCodeView',
  components: { ErrorText, TheLoader, OtpPinForm, CellPhoneKeypad },
  setup () {
    const notifyStore = useNotifyStore()
    const { fromBackground } = storeToRefs(notifyStore)
    const { getTokenFromBiometry, setBiometry, isBiometryActive } = useBiometry()
    const userStore = useUserStore()
    const { setUserToken, setHasPinCode, setUser, setUserPhone, setIsForcedSms } = userStore
    const { userToken, userPhone, user } = storeToRefs(userStore)

    const router = useRouter()
    const loading = ref(false)
    const responseError = ref(false)
    const responseErrorMessage = ref('Произошла ошибка, попробуйте позже.')
    const pinError = ref(false)
    const pinErrorMessage = ref({
      msg: '',
      error: false
    })
    const pin = ref('')
    const platform = getPlatform()
    const appVersionIos = process.env.VUE_APP_IOS_VERSION
    const appVersionAndroid = process.env.VUE_APP_ANDROID_VERSION

    const submitPin = async () => {
      responseError.value = false
      try {
        loading.value = true

        await loginUser(userPhone.value, pin.value)
        const token = await getUserToken(userPhone.value, pin.value)
        setUserToken(token)
        await setUser(token)
        await router.push({ name: 'home' })
        showToast({ title: 'Добро пожаловать!', icon: 'success' })
      } catch (error) {
        if (error.code === 'reset_pincode') {
          goToPhoneForm()
          return
        } else if (error.code === 'incorrect_code') {
          pin.value = ''
          pinError.value = true
          pinErrorMessage.value.error = true
          pinErrorMessage.value.msg = error.text
        } else {
          responseError.value = true
          responseErrorMessage.value = error.message
        }
      } finally {
        loading.value = false
      }

      if (userToken.value && !user.is_pin) {
        showFingerPrint(userToken.value)
      }
    }

    const goToPhoneForm = () => {
      setIsForcedSms(true)
      setHasPinCode(false)
      setUserPhone(false)
      setBiometry(false)
    }

    const noAuthLogin = async () => {
      await router.push({ name: 'home' })
    }

    const initBiometry = async () => {
      try {
        const token = await getTokenFromBiometry()
        setUserToken(token)
        await setUser(token)
        if (fromBackground.value) {
          return
        }
        router.push({
          name: 'home'
        })
        showToast({ title: 'Добро пожаловать!', icon: 'success' })
      } catch {
        deleteCookieToken()
      }
    }

    const changePin = async (currNum) => {
      if (currNum === -1 && pin.value.length === 0 && isBiometryActive.value) {
        await initBiometry()
      }

      if (currNum === '') noAuthLogin()
      if (pin.value.length === 0 && currNum === -1) return
      if (pin.value.length > 6) return
      if (pinError.value) pinError.value = false
      if (currNum !== -1 && pin.value.length < 6) pin.value += currNum
      if (currNum === -1) pin.value = pin.value.slice(0, -1)
    }

    onActivated(() => {
      pin.value = ''
    })
    onMounted(() => {
      pin.value = ''
    })

    return {
      loading,
      responseError,
      responseErrorMessage,
      pin,
      pinError,
      pinErrorMessage,
      platform,
      appVersionAndroid,
      appVersionIos,
      submitPin,
      goToPhoneForm,
      changePin
    }
  }
}
</script>

<style scoped lang="scss">
.pin {
  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;

    @include desktop {
      color: #082B53;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    @include desktop {
      display: none;
    }

    &.pin__logo--desktop {
      display: none;
      @include desktop {
        display: flex;
        img {
          width: 100px;
        }
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
  }
}

.pin-keypad {
  margin-top: 40px;
}

.pin-help-block {
  padding: 0 30px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;

  @include desktop {
    color: #002856;
  }
}
.pin-skip-btn {
  all: unset;
  margin-top: 37px;
  text-align: center;

  @include desktop {
    color: #002856;
  }
}

.pin-version-info {
  margin-top: 16px;
  color: #7F8DA9;
}

.error {
  color: #FF1F00
}

.pin-error {
  margin: 0;
}

p {
  font-size: 14px;
  font-weight: 350;
  line-height: 14px;
  text-align: center;
  margin-bottom: 24px;
  color: #002856;
}
</style>
