export const mobileMenuButtons = [
  {
    smallIconName: 'chat-icon',
    title: 'Чат с пациентом',
    subtitle: '',
    isWide: true,
    bigIconName: '',
    bgColor: '#E1E8F4',
    to: '/chats-for-doctor'
  },
  {
    smallIconName: 'doctor-simple-icon',
    title: 'Запись к врачу',
    subtitle: 'Прием в клинике, телемедицина или выезд на дом',
    isWide: true,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: ''
  },
  {
    smallIconName: 'services-icon',
    title: 'Услуги',
    subtitle: '',
    isWide: false,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: '/services/search-departments'
  },
  {
    smallIconName: 'special-offers-icon',
    title: 'Акции',
    subtitle: '',
    isWide: false,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: '/offers'
  },
  {
    smallIconName: 'programs-simple-icon',
    title: 'Комплексные программы',
    subtitle: 'Выгода для наших пациентов',
    isWide: true,
    bigIconName: 'heart-discount',
    bgColor: '#E4FCE6',
    to: '/program/all'
  },
  {
    smallIconName: 'document-simple-icon',
    title: 'Онлайн регистратура',
    subtitle: '',
    isWide: false,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: '/patient-member'
  },
  {
    smallIconName: 'user-profile-icon',
    title: 'Профиль пользователя',
    subtitle: '',
    isWide: false,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: '/profile/details'
  },
  {
    smallIconName: 'files-simple-icon',
    title: 'Мои файлы',
    subtitle: '',
    isWide: false,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: '/files'
  },
  {
    smallIconName: 'favorites-icon',
    title: 'Избранное',
    subtitle: '',
    isWide: false,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: '/favorites/favorites-doctors'
  },
  {
    smallIconName: 'parking-icon',
    title: 'Пропуск на парковку',
    subtitle: 'Заказ бесплатного парковочного места',
    isWide: true,
    bigIconName: 'parking-sign',
    bgColor: '#E1E8F4',
    to: '/profile/car'
  },
  {
    smallIconName: 'finances-icon',
    title: 'Финансы',
    subtitle: 'Оплата услуг и счетов в приложении',
    isWide: true,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: '/finance'
  },
  {
    smallIconName: 'assistant-icon',
    title: 'Поддержка FAQ',
    subtitle: '',
    isWide: false,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: '/call-center-faq'
  },
  {
    smallIconName: 'document-simple-icon',
    title: 'Подготовка к исследованиям',
    subtitle: '',
    isWide: false,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: '/preparation'
  },
  {
    smallIconName: 'document-simple-icon',
    title: 'Форма возврата НДФЛ',
    subtitle: '',
    isWide: false,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: '/tax-form'
  },
  {
    smallIconName: 'tech-support-icon',
    title: 'Техподдержка',
    subtitle: '',
    isWide: false,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: '/support/form'
  },
  {
    smallIconName: 'whatsapp-new-icon',
    title: 'Написать WhatsApp',
    subtitle: '',
    isWide: false,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: ''
  },
  {
    smallIconName: 'address-simple-icon',
    title: 'Адреса K+31',
    subtitle: '',
    isWide: false,
    bigIconName: '',
    bgColor: '#EEF1F3',
    to: ''
  }
]

export const mobileMenuButtonsApp = [
  // {
  //   icon: 'settings-icon',
  //   title: 'Настройки приложения',
  //   type: '',
  //   to: '/'
  // },
  {
    icon: 'exit-icon',
    title: 'Выйти из приложения',
    type: 'app-exit',
    to: ''
  }
]
export const mobileMenuButtonsDoctor = [
  {
    icon: 'clinic-icon',
    title: 'Запись в клинику',
    type: '',
    to: '/online-appointment'
  },
  {
    icon: 'pc-icon',
    title: 'Телемедицина',
    type: '',
    to: '/agreement-appointment'
  },
  {
    icon: 'home-icon',
    title: 'Вызов на дом',
    type: '',
    to: '/call-doctor'
  },
  {
    icon: 'chat-icon',
    title: 'Чат с врачом',
    type: '',
    to: '/chats'
  }
]
