<script>
import { useAppInfoStore } from '@/stores/AppInfoStore'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'MenuButtonsList',
  props: {
    buttons: Array
  },
  setup () {
    const userStore = useUserStore()
    const appStore = useAppInfoStore()
    const { appInfo } = storeToRefs(appStore)

    const { logOut } = userStore

    const checkIfHidden = (title) => {
      if (title === 'Чат с врачом') {
        return appInfo.value?.is_doctor_chat
      } else {
        return true
      }
    }

    const handleButtonClick = (button) => {
      if (!button.to) {
        if (button.title === 'Выйти из приложения') {
          logOut()
        }
      }
    }
    return { handleButtonClick, checkIfHidden }
  }
}
</script>

<template>
  <div class="menu-buttons">
    <component
      v-for="button in buttons"
      :is="button.to ? 'router-link' : 'div'"
      :to="button.to"
      :key="button.title"
      @click="handleButtonClick(button)"
      class="menu-button">
    <div class="menu-button" v-show="checkIfHidden(button.title)">
      <div class="icon-wrapper">
        <img
          :src="require(`@/assets/uploads/icon/common/${button.icon}.svg`)"
          :alt="button.title" />
      </div>
      <p>{{ button.title }}</p>
    </div>
    </component>
  </div>
</template>

<style lang="scss" scoped>
.menu-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.menu-button {
  display: flex;
  gap: 16px;
  align-items: center;

  > p {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #002856;
  }
}

.icon-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: #eef1f3;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
