<template>
  <div class="modal-deposit" :class="{ active: isDepositOpen }">
    <div class="container">
      <div class="modal-deposit__title">
        <a class="section-title__back" @click.prevent="$emit('closeDeposit')">
          <img
            src="@/assets/uploads/icon/common/arrow-back.svg"
            alt="стрелка"
          />
        </a>
        <h3>Внесение депозита</h3>
      </div>
      <TheLoader v-if="loading"> Загрузка</TheLoader>
      <div v-if="!loading">
        <div v-if="paymentResult === 'success'" class="finance__suc">
          Оплата успешно прошла!
        </div>
        <div v-if="paymentResult === 'failed'" class="finance__err">
          Оплата не прошла, попробуйте ещё раз.
        </div>
        <div class="finance-info">
          <img
            class="finance-info-img"
            src="@/assets/uploads/icon/common/blue-warning.svg"
            alt="важно"
          />
          <div class="finance-info__text">
            <span class="finance-info__text-head"
              >Обратите внимание, депозит будет внесен для использования в
              выбранном филиале.</span
            >
            <span class="finance-info__text-main"
              >Если вы планируете посетить несколько филиалов, внесите средства
              в каждый из них</span
            >
          </div>
        </div>
        <FilialsForm
          :isDeposit="true"
          @select-clinic="(id) => (legalEntity = id)"
        />
        <div class="finance__control" v-if="!loading">
          <div class="finance__control-label">
            Введите сумму депозита (руб.)
          </div>
          <input
            type="number"
            class="finance__payment"
            name="finance-payment"
            v-model="paymentData.summ"
            ref="sumInput"
          />
          <div class="error" v-if="errorMessage">
            {{ errorMessage }}
          </div>
          <button
            class="finance__btn"
            type="button"
            @click.prevent="openPaymentModal"
          >
            Внести оплату
          </button>
          <div ref="bottom"></div>
          <ErrorText v-if="errorUrl" :error="errorUrl" />
        </div>
      </div>
    </div>
  </div>
  <ModalPayment
    :class="{ modalActive: isPaymentModalOpen }"
    :isDeposit="true"
    :paymentData="paymentData"
    :currentFilial="legalEntity"
    @close-payment="clearInput"
  ></ModalPayment>
</template>

<script>
import ModalPayment from '@/components/pages/finance/ModalPayment.vue'
import { computed, reactive, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/UserStore'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useVuelidate } from '@vuelidate/core'
import { minValue, required } from '@vuelidate/validators'
import ErrorText from '@/components/ui/ErrorText.vue'
import FilialsForm from '@/components/pages/online-appointment/FilialsForm.vue'

export default {
  components: {
    FilialsForm,
    ErrorText,
    TheLoader,
    ModalPayment
  },
  emits: ['closeDeposit'],
  props: {
    isDepositOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    finance: {
      type: [Object],
      required: true
    }
  },
  setup (props) {
    const currentFilial = ref(1)
    const legalEntity = ref(1)
    const paymentResult = ref(null)
    const isPaymentModalOpen = ref(false)
    const userStore = useUserStore()
    const { getUserId } = storeToRefs(userStore)
    const paymentLink = ref('')
    const sumInput = ref(null)
    const bottom = ref(null)
    const loading = ref(false)
    const errorUrl = ref('')
    const clinics = {
      1: 'На Лобачевского',
      2: 'Петровские ворота. Поликлиника',
      3: 'Петровские ворота. Хирургия',
      5: 'Запад'
    }

    const paymentData = reactive({
      summ: '',
      link: '',
      clinic: ''
    })
    const clearInput = () => {
      isPaymentModalOpen.value = false
      paymentData.summ = ''
      paymentData.success = false
      paymentData.link = ''
      paymentData.clinic = ''
      v$.value.$reset()
    }
    const errorMessage = ref('')
    const rules = {
      summ: {
        required,
        minValue: minValue(50)
      }
    }
    const currentFinance = computed(() => {
      if (!props.finance) return null
      return props.finance.find((el) => el.id === legalEntity.value)
    })

    const v$ = useVuelidate(rules, paymentData)

    const openPaymentModal = async () => {
      v$.value.$touch()
      if (v$.value.$invalid) {
        if (v$.value.summ.required.$invalid) {
          errorMessage.value = 'Необходимо указать сумму'
        } else if (v$.value.summ.minValue.$invalid) {
          errorMessage.value = 'Минимальная сумма оплаты: 50 рублей'
        }
      } else {
        paymentData.legalEntityId = currentFinance.value.id
        paymentData.clinic = 'K+31' + ' ' + '«' + clinics[currentFinance.value.id] + '»'

        isPaymentModalOpen.value = true
      }
    }

    const formatDeposit = (sum) => {
      return new Intl.NumberFormat('ru-RU', {
        maximumSignificantDigits: 6
      })
        .format(Math.abs(sum) / 100)
        .toLocaleString('en', { useGrouping: true })
        .replaceAll(',', ' ')
    }

    watch(paymentData, () => {
      if (errorMessage.value.length > 0) {
        errorMessage.value = ''
      }
    })

    return {
      loading,
      paymentData,
      v$,
      openPaymentModal,
      errorMessage,
      paymentLink,
      clearInput,
      sumInput,
      bottom,
      errorUrl,
      paymentResult,
      currentFilial,
      legalEntity,
      formatDeposit,
      getUserId,
      isPaymentModalOpen
    }
  }
}
</script>

<style scoped lang="scss">
.modal-deposit {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  padding: 12px 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: 1;
  &.active {
    top: 0;
    opacity: 1;
    height: 100%;
  }
}

.modal-deposit__title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin-bottom: v-bind('mb');

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  img {
    filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg)
      brightness(102%) contrast(103%);
  }
}

.section-title__back {
  cursor: pointer;
}

.finance-info {
  margin-top: 12px;
  display: flex;
  padding: 16px 13.5px;
  gap: 12px;
  background-color: #e1e8f4;
  border-radius: 5px;
}
.finance-info-img {
  align-self: start;
}
.finance-info__text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.finance-info__text-head {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #002856;
}
.finance-info__text-main {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #002856;
}

.finance__filial {
  margin-bottom: 24px;
}

.finance__department-list {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.finance__department {
  cursor: pointer;
  border: unset;
  border-radius: 30px;
  background: $blue-extra-light;
  padding: 8px 16px;
  color: #062e4f;
  font-size: 14px;
  line-height: 18px;
}

.finance__department.active {
  background: $blue;
  color: white;
}

.finance__info {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #002856;
}

.finance__company {
  color: #111;
}

.finance__type {
  color: $blue-grey;
}

.finance__balance {
  display: flex;
  align-items: start;
  gap: 8px;
  color: $blue;
  line-height: 18px; /* 112.5% */
}

.finance__balance--debt {
  color: $red;
}

.finance__suc {
  font-size: 18px;
  margin-bottom: 10px;
  color: green;
}

.finance__err {
  margin-bottom: 10px;
  font-size: 18px;
  color: $red;
}

.finance__list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 24px;
  @include desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 24px;
  }
}

.finance__payment {
  border-radius: 8px;
  border: 1px solid #bac7de;
  background: #fff;
  width: 100%;
  height: 40px;
  padding: 11px;

  &::placeholder {
    color: #bac7de;
    font-size: 14px;
    font-weight: 350;
    line-height: 100%;
  }
}

.finance__btn {
  color: #062e4f;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  border-radius: 30px;
  background: var(--blue-1, #bac7de);
  padding: 11px;
  width: 100%;
  border: none;
  margin-top: 16px;
  margin-bottom: 24px;
}

.finance__control-label {
  margin-top: 16px;
  color: #002856;
  font-size: 14px;
  font-weight: 350;
  line-height: 100%;
  margin-bottom: 4px;
}

.error {
  margin-top: 5px;
  margin-bottom: 10px;
  color: $red;
}

.modalActive {
  top: 0;
  opacity: 1;
  height: 100%;
}
</style>
