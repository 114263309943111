import axios from 'axios'

// code - как пин, так и смс
export const loginUser = async (phone, code) => {
  const response = await axios.post('/v2/element/user/auth-otp', {
    phone,
    code,
    device_uuid: window?.device?.uuid
  })

  // пин-код неверный || 3 попытки прошли
  if (response.data.error_code === 'incorrect_code' || response.data.error_code === 'reset_pincode') {
    const error = new Error(`Error code: ${response.data.error_code}, Message: ${response.data.message}`)
    error.code = response.data.error_code
    error.text = response.data.message
    throw error
  }

  if (response.data.status !== 'ok') {
    const errorMessage = response.data?.message ?? 'Нет доступа к серверу. Попробуйте позже.'
    throw new Error(errorMessage)
  }

  return response
}
