<template>
  <Teleport to="body">
    <Transition name="overlay">
      <div
        v-if="isDrawerOpen"
        @click="$emit('closeDrawer')"
        class="drawer__overlay">
        <Transition name="wrapper" appear>
          <div v-if="isDrawerOpen" class="drawer__wrapper">
            <div class="drawer__content">
              <div class="gray-ribbon"></div>
              <div class="appointment-menu__title">
                <p v-if="title">{{ title }}</p>
                <p v-if="subtitle">{{ subtitle }}</p>
              </div>
              <slot></slot>
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
export default {
  name: 'BottomDrawer',
  emits: ['closeDrawer'],
  props: {
    isDrawerOpen: Boolean,
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
.drawer__overlay {
  position: fixed;
  background: #00285680;
  backdrop-filter: blur(1px);
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 201;
  overflow-y: scroll;
  overflow-x: hidden;
}

.drawer__wrapper {
  width: 100%;
  height: fit-content;
  z-index: 202;
  position: absolute;
  bottom: 0;
  left: 0;
}

.drawer__content {
  padding: 34px 16px 80px 16px;
  position: relative;
  max-height: calc(100vh - 80px);
  width: 100%;
  height: fit-content;
  overflow-y: auto;
  box-shadow: 0 5px 15px 0 #00000026;
  background: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.gray-ribbon {
  position: absolute;
  top: 8px;

  width: 40px;
  height: 4px;
  background-color: #e4e4e4;
  border-radius: 12px;

  transform: translateX(-50%);
  left: 50%;
}

.appointment-menu__title {
  margin-bottom: 16px;
  > p:nth-of-type(1) {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #002856;
  }
  > p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #7f8da9;
  }
}

.overlay-enter-active,
.overlay-leave-active {
  transition: opacity 0.3s ease;
}

.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
}

.wrapper-enter-active,
.wrapper-leave-active {
  transition: transform 0.4s ease;
}

.wrapper-enter-from {
  transform: translateY(400%);
}

.wrapper-enter-to {
  transform: 0;
}

.wrapper-leave-from {
  transform: 0;
}

.wrapper-leave-to {
  transform: translateY(400%);
}
</style>
