<template>
<div class="sms">
  <TheLoader v-if="loading" />
  <form
    v-else
    action=""
    class="form-sms"
    @submit.prevent="submitSmsCode"
  >
  <TheTitle>
    <h3>Вход в личный кабинет пациента</h3>
  </TheTitle>

    <div
      class="form-sms__group"
      :class="{ 'form-group--error': v$.code.$error || error }"
    >
      <OtpForm
        v-model="userInfo.code"
        :digit-count="6"
        :title="'Код из смс'"
        :error="v$.code.$error || error"
        @is-completed="error = false"
      />

      <div v-if="v$.code.$error || error" class="error help-block">
        {{ "Необходимо ввести смс код" }}
      </div>
      <div v-if="responseError" class="error help-block">
        {{ responseErrorMessage }}
      </div>
    </div>

    <CustomButton tag="button" type="submit" marginBottom="20px">
      Войти
    </CustomButton>

    <a
      href="https://www.k31.ru/upload/doc/useterms.pdf"
      target="_blank"
      class="form-sms__rules"
    >
      Пользовательское соглашение
    </a>

    <router-link to="/">
      <p style="text-align: center;color: #002856;font-size: 15px;"><br />Вернуться на главную</p>
    </router-link>
  </form>
</div>
</template>

<script>
import { computed, reactive, ref, toRefs, watch } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import { postLog } from '@/api/postLog'
import { showToast } from '@/utilits/showToast'
import { loginUser } from '@/api/authentication/loginUser'
import { getUserToken } from '@/api/authentication/getUserToken'
import OtpForm from '@/components/pages/auth/OtpForm.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import TheTitle from '@/components/ui/typography/TheTitle.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'

export default {
  name: 'SmsForm',
  components: { TheLoader, OtpForm, CustomButton, TheTitle },
  setup () {
    const loading = ref(false)
    const error = ref(false)
    const responseError = ref(false)
    const responseErrorMessage = ref('Произошла ошибка, попробуйте позже.')

    const router = useRouter()
    const userStore = useUserStore()

    const { userPhone, hasPinCode } = toRefs(userStore)
    const { setUserToken, setUser, setUserCards, setUserSmsCode } = userStore

    const userInfo = reactive({
      code: ''
    })

    const rules = {
      code: { required }
    }
    const v$ = useVuelidate(rules, userInfo)

    const isPhone = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform === 'ios' || platform === 'android'
    })

    const submitSmsCode = async () => {
      responseError.value = false
      v$.value.$touch()
      if (v$.value.$error || userInfo.code.length !== 6) {
        error.value = true
        return
      }
      error.value = false

      try {
        loading.value = true
        const response = await loginUser(userPhone.value, userInfo.code)
        const action = response.data.data.action

        if (action === 'login') {
          const token = await getUserToken(userPhone.value, userInfo.code)
          setUserToken(token)
          await setUser(token)

          if (!hasPinCode.value && isPhone.value) {
            await router.push({ name: 'auth-pin-code' })
          } else {
            await router.push({ name: 'home' })
          }

          showToast({ title: 'Добро пожаловать!', text: '', icon: 'success' })
        }

        if (action === 'choose') {
          const cards = response.data.data.cards
          setUserCards(cards)
          setUserSmsCode(userInfo.code)
          await router.push({ name: 'auth-members' })
        }

        if (action === 'signup') {
          setUserSmsCode(userInfo.code)
          await router.push({ name: 'register-new' })
        }
      } catch (error) {
        if (error.code === 'incorrect_code') {
          responseError.value = true
          responseErrorMessage.value = 'Введенный код неверен'
        } else {
          responseError.value = true
          responseErrorMessage.value = error.message

          postLog({
            m: error.message,
            err: error
          })
        }
      } finally {
        loading.value = false
      }
    }

    watch(
      () => userInfo.code,
      () => {
        responseErrorMessage.value = ''
        if (userInfo.code.length === 6) {
          v$.value.$touch()

          error.value = false
        }
      }
    )

    return {
      userInfo,
      submitSmsCode,
      v$,
      error,
      responseError,
      loading,
      responseErrorMessage
    }
  }
}
</script>

<style scoped lang="scss">
.form-sms {
  width: 100%;
  max-width: 280px;

  &__group {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    & .help-block {
    }
  }

  &__label {
    text-align: center;
    color: $blue;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 5px;

    &.error {
      color: $red;
    }
  }

  &__inputs {
    justify-content: center;
    margin-bottom: 25px;
  }

  &__sms {
    margin-bottom: 25px;
  }

  &__rules {
    display: block;
    text-align: center;
    color: $blue;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.3px;
  }
}
</style>
