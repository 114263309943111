<template>
  <section class="register">
    <div class="register__logo">
      <img src="@/assets/uploads/images/header/logo.svg" alt="логотип к31">
    </div>

    <div class="register__logo register__logo--desktop">
      <img src="@/assets/uploads/images/header/logo-blue.svg" alt="логотип к31">
    </div>

    <div class="container register__container">

      <TheTitle>
        <h3>
          Регистрация
        </h3>
      </TheTitle>

      <router-view></router-view>

    </div>
  </section>
</template>

<script>
import TheTitle from '@/components/ui/typography/TheTitle.vue'

export default {
  name: 'RegisterView',
  components: { TheTitle }
}
</script>

<style scoped lang="scss">
.register {
  display: flex;
  flex-direction: column;
  padding: 60px 15px 15px 15px;
  min-height: 100vh;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    box-shadow: 1px 1px 2.5rem 1px rgba(0, 0, 0, .1);
    padding: 20px;
    border-radius: 8px;
    background: #FFFFFF;
    @include desktop {
      max-width: 800px;
      padding: 56px 104px;
    }
  }

  &__logo {
    margin: 0 auto 30px auto;
    max-width: 100px;
    @include desktop {
      display: none;
    }
  }

  &__logo--desktop {
    display: none;
    @include desktop {
      display: block;
    }
  }

  &__form {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }

  &__group {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  &__recovery-pass {
    text-align: center;
  }

  &__input {
    border: unset;
    border-bottom: 1px solid #01468f;
  }

  &__rules {
    text-align: center;
  }
}

</style>
