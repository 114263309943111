<template>
  <footer class="footer" :style="{ paddingBottom: footerPadding }">
    <div class="container footer__container">
      <Teleport to="main">
        <!-- use the modal component, pass in the prop -->
        <TheMenu :show="menuIsActive" @close="closeMenu">
          <template #header>
            <h3>custom header</h3>
          </template>
        </TheMenu>
      </Teleport>

      <nav class="footer__nav">
        <ul class="footer__list" @click="closeMenu">
          <li class="footer__item" :class="{ 'active-link': routePath === '/' }">
            <router-link to="/" @click.prevent="handleModal" class="footer__link">
              <img src="@/assets/uploads/images/footer/home-footer.svg" alt="Главная" />
              <span>Главная</span>
            </router-link>
          </li>
          <li class="footer__item" :class="{ 'active-link': routePath.includes('/doctors') }">
            <router-link to="/doctors" class="footer__link">
              <img src="@/assets/uploads/images/footer/doctor-footer.svg" alt="картинка доктора" />
              <span>Врачи</span>
            </router-link>
          </li>

          <li class="footer__item" :class="{ 'active-link': routePath.includes('/med-card') }">
            <router-link to="/med-card" class="footer__link">
              <img src="@/assets/uploads/images/footer/med-card.svg" alt="Медкарта" />
              <span>Медкарта</span>
            </router-link>
          </li>

          <li class="footer__item" :class="{ 'active-link': routePath.includes('/calendar') }">
            <router-link to="/calendar" class="footer__link">
              <img src="@/assets/uploads/images/footer/calendar.svg" alt="Календари" />
              <span>Календари</span>
            </router-link>
          </li>

          <li class="footer__item">
            <router-link to="/menu" class="footer__link-menu">
              <img src="@/assets/uploads/images/footer/menu-icon.svg" alt="точки меню" />
            </router-link>
          </li>
        </ul>
      </nav>

      <!-- <button class="footer__menu" @click="toggleMenu" :class="{ 'active-link': menuIsActive }">
          <img src="@/assets/uploads/images/footer/menu-icon.svg" alt="точки меню" />
      </button> -->

    </div>
  </footer>
</template>

<script>
import TheMenu from '@/components/layout/menu/TheMenu.vue'
import { useMenuStore } from '@/stores/MenuStore'
import { storeToRefs } from 'pinia'
import { useModalPayment } from '@/composables/ui/useModalPayment.js'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default {
  name: 'TheFooter',
  components: { TheMenu },
  props: {
    footerPadding: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup () {
    const menuStore = useMenuStore()
    const { closeModalPayment, isModalPaymentOpened } = useModalPayment()
    const { closeMenu, toggleMenu } = menuStore
    const { menuIsActive } = storeToRefs(menuStore)
    const route = useRoute()

    const handleModal = () => {
      if (isModalPaymentOpened.value) {
        closeModalPayment()
      }
    }

    const routePath = computed(() => {
      return route.fullPath
    })

    return {
      menuIsActive,
      routePath,
      closeMenu,
      toggleMenu,
      handleModal
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  background: rgba(255, 255, 255, 0.3);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px 6px 0px 0px;

  -webkit-backdrop-filter: blur(12px) saturate(70%);
  backdrop-filter: blur(12px) saturate(70%);

  @include desktop {
    display: none;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 0;
  }

  &__menu {
    display: flex;
    border-radius: 8px;
    border: unset;
    font-size: 12px;
    line-height: 11px;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    width: 48px;
    height: 44px;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 16px;

    //&:hover {
    //  background: #BAC7DE;
    //  color: #002856;
    //}

    //&:active {
    //  background: #BAC7DE;
    //  color: #002856;
    //}
  }

  &__nav {
    flex: 1 1 auto;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__item {
    span {
      white-space: nowrap;
    }
  }

  &__link {
    position: relative;
    display: flex;
    padding: 12px 0px 23px;
    width: 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    max-width: 75px;
    z-index: 2;

    span {
      color: #002856;
      // color: $blue-grey;

      font-family: Circe;
      font-size: 9.5px;
      font-weight: 700;
      line-height: 9px;
      text-align: center;
    }

    &:hover {
      color: #002856;
    }

    &:active {
      // color: #002856;
    }

    & img {
      min-width: 24px;
      min-height: 24px;
      filter: brightness(0) saturate(100%) invert(11%) sepia(91%) saturate(1874%) hue-rotate(196deg) brightness(94%) contrast(103%);
      // filter: brightness(0) saturate(100%) invert(63%) sepia(11%) saturate(766%) hue-rotate(182deg) brightness(85%) contrast(92%);
    }
  }
}

.footer__menu {
  img {
    filter: brightness(0) saturate(100%) invert(11%) sepia(91%) saturate(1874%) hue-rotate(196deg) brightness(94%) contrast(103%);
    // filter: brightness(0) saturate(100%) invert(63%) sepia(11%) saturate(766%) hue-rotate(182deg) brightness(85%) contrast(92%);
  }
}

// .active-link {
//   img {
//     filter: brightness(0) saturate(100%) invert(11%) sepia(91%) saturate(1874%) hue-rotate(196deg) brightness(94%) contrast(103%) !important;
//   }

//   span {
//     color: $blue;
//   }
.footer__link-menu {
  padding: 12px 0px 23px;
  width: 70px;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;

  >img {
    margin-bottom: 14px;
  }
}
</style>
