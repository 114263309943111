<template>
  <div class="modal-deposit" :class="{ active: isBillsOpen }">
    <div class="container">
      <div class="modal-deposit__title">
        <a class="section-title__back" @click.prevent="$emit('closeDeposit')">
          <img
            src="@/assets/uploads/icon/common/arrow-back.svg"
            alt="стрелка"
          />
        </a>
        <h3>Оплата счетов</h3>
      </div>
      <TheLoader v-if="loading"> Загрузка</TheLoader>
      <div v-if="!loading">
        <div v-if="paymentResult === 'success'" class="finance__suc">
          Оплата успешно прошла!
        </div>
        <div v-if="paymentResult === 'failed'" class="finance__err">
          Оплата не прошла, попробуйте ещё раз.
        </div>
        <FilialsForm
          :isDeposit="false"
          :finance="finance"
          @select-clinic="(id) => (legalEntity = id)"
        />
        <div class="finance-bills">
          <nav class="finance-bills__header">
            <span class="finance-bills__header-text">Счета на оплату</span>
            <!-- <button class="finance-bills__btn">
              <img
                src="@/assets/uploads/images/payment/archive.svg"
                alt="настройки"
              />Архив счетов
            </button> -->
          </nav>
          <div
            v-if="currentBalanceIsDebt || (legalEntity === 0 && isThereDebt)"
            class="finance-info"
          >
            <img
              class="finance-info-img"
              src="@/assets/uploads/icon/common/payment-warning.svg"
              alt="важно"
            />
            <div class="finance-info__text">
              <span class="finance-info__text-head"
                >У вас имеется задолженность.</span
              >
              <span class="finance-info__text-main"
                >Просьба оплатить счета</span
              >
            </div>
          </div>
          <div
            v-if="currentBalanceIsDebt || (legalEntity === 0 && isThereDebt)"
            class="finance-info__bills-list"
          >
            <div v-for="clinic in clinicsToShow" :key="clinic.id">
              <div
                v-for="invoice in clinic.invoice"
                :key="invoice.num"
                class="bill-item"
                @click="submitPayment(invoice.amount / 100, clinic.id)"
              >
                <div class="bill-item__header">
                  <span class="bill-item__sum-text">Сумма (руб.)</span>
                  <img
                    class="finance-menu__options-btn-arrow"
                    src="@/assets/uploads/icon/common/right-small-arrow.svg"
                    alt="стрелка"
                  />
                </div>
                <div class="bill-item__sum">
                  {{ formatDeposit(invoice.amount) }}
                </div>
                <footer class="bill-item__footer">
                  <img
                    class="finance-menu__options-btn-arrow"
                    src="@/assets/uploads/images/payment/location-icon.svg"
                    alt="локация"
                  />
                  <span class="bill-item__address">{{ clinics[clinic.id] }}</span>
                </footer>
              </div>
            </div>
          </div>
          <div
            v-if="legalEntity === 0 ? false : !currentBalanceIsDebt"
            class="finacne-info__no-bills-block"
          >
            <div class="finance-info__header">
              <img
                class="finance-info__header-woman"
                src="@/assets/uploads/images/payment/doctor-woman.svg"
              />
              <img
                class="finance-info__header-background"
                src="@/assets/uploads/images/calendar/pregnancy/background.svg"
              />
            </div>
            <div class="finance-info__no-bills-text">
              У вас нет неоплаченных счетов
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalPayment
    :class="{ modalActive: isPaymentModalOpen }"
    :isDeposit="false"
    :paymentData="paymentData"
    :currentFilial="clinicToPay"
    @close-payment="clearInput"
  ></ModalPayment>
</template>

<script>
import ModalPayment from '@/components/pages/finance/ModalPayment.vue'
import { computed, reactive, ref } from 'vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useVuelidate } from '@vuelidate/core'
import { minValue, required } from '@vuelidate/validators'
import FilialsForm from '@/components/pages/online-appointment/FilialsForm.vue'

export default {
  components: {
    FilialsForm,
    TheLoader,
    ModalPayment
  },
  emits: ['closeDeposit'],
  props: {
    isBillsOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    finance: {
      type: [Object],
      required: true
    },
    isThereDebt: Boolean
  },
  setup (props) {
    const isPaymentModalOpen = ref(false)
    const currentFilial = ref(0)
    const legalEntity = ref(0)
    const paymentResult = ref(null)
    const paymentLink = ref('')
    const sumInput = ref(null)
    const bottom = ref(null)
    const loading = ref(false)
    const errorUrl = ref('')
    const clinicToPay = ref(0)
    const clinics = {
      1: 'на Лобачевского',
      2: 'Петровские ворота. Поликлиника',
      3: 'Петровские ворота. Хирургия',
      5: 'Запад'
    }
    const clinicsToShow = computed(() => {
      if (legalEntity.value === 0) return props.finance
      return [props.finance.find((c) => c.id === legalEntity.value)]
    })
    const paymentData = reactive({
      payment: '',
      link: '',
      clinic: ''
    })
    const clearInput = () => {
      isPaymentModalOpen.value = false
      paymentData.payment = ''
      paymentData.success = false
      paymentData.link = ''
      paymentData.clinic = ''
      v$.value.$reset()
    }
    const errorMessage = ref('')
    const rules = {
      summ: {
        required,
        minValue: minValue(50)
      }
    }
    const currentFinance = computed(() => {
      if (!props.finance) return null
      return props.finance.find((el) => el.id === legalEntity.value)
    })

    const currentBalanceIsDebt = computed(() => {
      if (!currentFinance.value) return null
      return currentFinance.value.invoice.length > 0
    })

    const v$ = useVuelidate(rules, paymentData)

    const submitPayment = async (deposit, clinicId) => {
      paymentData.summ = deposit
      paymentData.legalEntityId = clinicId
      clinicToPay.value = clinicId
      paymentData.clinic =
            'K+31' + ' ' + '«' + clinics[clinicId] + '»'

      isPaymentModalOpen.value = true
    }

    const formatDeposit = (sum) => {
      return new Intl.NumberFormat('ru-RU', {
        maximumSignificantDigits: 6
      })
        .format(Math.abs(sum) / 100)
        .toLocaleString('en', { useGrouping: true })
        .replaceAll(',', ' ')
    }

    return {
      loading,
      paymentData,
      v$,
      submitPayment,
      errorMessage,
      currentFinance,
      paymentLink,
      clearInput,
      sumInput,
      bottom,
      errorUrl,
      paymentResult,
      currentFilial,
      legalEntity,
      formatDeposit,
      currentBalanceIsDebt,
      clinicsToShow,
      clinicToPay,
      clinics,
      isPaymentModalOpen
    }
  }
}
</script>

<style scoped lang="scss">
.modal-deposit {
  overflow-y: scroll;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  padding: 12px 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: 1;
  &.active {
    top: 0;
    opacity: 1;
    height: 100%;
  }
}

.modal-deposit__title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin-bottom: v-bind('mb');

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  img {
    filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg)
      brightness(102%) contrast(103%);
  }
}

.section-title__back {
  cursor: pointer;
}

.finance-info {
  margin-top: 12px;
  display: flex;
  padding: 16px 13.5px;
  gap: 12px;
  background-color: #f1842c4d;
  border-radius: 5px;
}
.finance-info-img {
  align-self: start;
}
.finance-info__text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.finance-info__text-head {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #002856;
}
.finance-info__text-main {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #002856;
}

.finance__filial {
  margin-bottom: 24px;
}

.finance__department-list {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.finance__department {
  cursor: pointer;
  border: unset;
  border-radius: 30px;
  background: $blue-extra-light;
  padding: 8px 16px;
  color: #062e4f;
  font-size: 14px;
  line-height: 18px;
}

.finance__department.active {
  background: $blue;
  color: white;
}

.finance__info {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #002856;
}

.finance__company {
  color: #111;
}

.finance__type {
  color: $blue-grey;
}

.finance__balance {
  display: flex;
  align-items: start;
  gap: 8px;
  color: $blue;
  line-height: 18px; /* 112.5% */
}

.finance__balance--debt {
  color: $red;
}

.finance__suc {
  font-size: 18px;
  margin-bottom: 10px;
  color: green;
}

.finance__err {
  margin-bottom: 10px;
  font-size: 18px;
  color: $red;
}

.finance__list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 24px;
  @include desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 24px;
  }
}

.finance__payment {
  border-radius: 8px;
  border: 1px solid #bac7de;
  background: #fff;
  width: 100%;
  height: 40px;
  padding: 11px;

  &::placeholder {
    color: #bac7de;
    font-size: 14px;
    font-weight: 350;
    line-height: 100%;
  }
}

.finance__btn {
  color: #062e4f;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  border-radius: 30px;
  background: var(--blue-1, #bac7de);
  padding: 11px;
  width: 100%;
  border: none;
  margin-top: 16px;
  margin-bottom: 24px;
}

.finance__control-label {
  margin-top: 16px;
  color: #002856;
  font-size: 14px;
  font-weight: 350;
  line-height: 100%;
  margin-bottom: 4px;
}

.error {
  margin-top: 5px;
  margin-bottom: 10px;
  color: $red;
}
.finance-bills {
  margin-top: 16px;
}
.finance-info__header {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.finance-info__header-background {
  position: absolute;
  top: 50%;
  transform: translateY(-10%);
  z-index: 0;
}

.finance-info__header-woman {
  z-index: 1;
}
.finance-info__no-bills-text {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
}

.finance-bills__btn {
  all: unset;
  width: fit-content;
  align-self: flex-end;
  img {
    margin-right: 6px;
  }
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #002856;
}

.finance-bills__header {
  display: flex;
  justify-content: space-between;
}
.finance-bills__header-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}

.finance-info__bills-list {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 30px;
}

.bill-item {
  box-shadow: 0 5px 15px 0 #00000026;
  border-radius: 6px;
  padding: 8px;
}
.bill-item__header {
  display: flex;
  justify-content: space-between;
}
.bill-item__sum-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #7f8da9;
}

.bill-item__sum {
  margin-top: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #002856;
}
.bill-item__footer {
  margin-top: 40px;
  display: flex;
  align-items: center;
}
.bill-item__address {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #002856;
}
.bill-item__date {
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #002856;
  margin-left: auto;
}

.modalActive {
  top: 0;
  opacity: 1;
  height: 100%;
}
</style>
