<script>
import { useAppInfoStore } from '@/stores/AppInfoStore'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

export default {
  name: 'MenuWideTile',
  emits: ['openDrawer'],
  props: {
    smallIconName: {
      type: String,
      default: '',
      required: true
    },
    title: {
      type: String,
      default: '',
      required: true
    },
    subtitle: {
      type: String,
      default: '',
      required: true
    },
    bigIconName: {
      type: String,
      default: '',
      required: false
    },
    bgColor: {
      type: String,
      default: '#EEF1F3',
      required: false
    },
    to: {
      type: String,
      default: '',
      required: true
    }
  },
  setup (props, { emit }) {
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const appStore = useAppInfoStore()
    const { appInfo } = storeToRefs(appStore)

    const isNotHidden = computed(() => {
      if (props.title === 'Чат с пациентом') {
        if (appInfo.value.is_doctor_chat && user.value?.is_doctor) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })

    const handleTileClick = () => {
      if (!props.to) {
        emit('openDrawer', props.title)
      }
    }
    return { handleTileClick, isNotHidden }
  }
}
</script>

<template>
  <component v-if="isNotHidden" :is="to ? 'router-link' : 'div'" :to="to" @click.prevent="handleTileClick" class="wide-tile__wrapper">
    <div class="wide-tile__small-icon">
      <img :src="require(`@/assets/uploads/icon/common/${smallIconName}.svg` )" :alt="smallIconName">
      <!-- <img src="@/assets/uploads/icon/common/person.svg" :alt="smallIconName" /> -->
    </div>

    <p class="wide-tile__title">{{ title }}</p>

    <p class="wide-tile__subtitle">{{ subtitle }}</p>

    <img
      v-if="bigIconName"
      class="wide-tile__big-icon"
      :src="require(`@/assets/uploads/images/banners/${bigIconName}.svg`)"
      alt="bigIconName" />
  </component>
</template>

<style lang="scss" scoped>
.wide-tile__wrapper {
  grid-column: span 2;
  border-radius: 12px;
  padding: 16px;
  background: v-bind(bgColor);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.wide-tile__small-icon {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
.wide-tile__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #002856;
  margin-bottom: 4px;
}
.wide-tile__subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #002856;
}
.wide-tile__big-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}
</style>
