<template>

  <TheLoader white class="login-loader" v-if="loading" />

  <div class="login-form-wrapper" v-else>

  <PhoneForm v-if="!userPhone && !hasPinCode" :savedPhone="savedPhone"/>

  <SmsForm v-if="userPhone && !hasPinCode" />

  <PinForm v-if="userPhone && hasPinCode" />

  <div v-if="isBrowser && !userPhone" class="download">
    <a target="_blank" :href="isoAppLink" class="login__app-link">
      <img
        src="@/assets/uploads/images/register/app-store.jpg"
        alt="Скачать из AppStore"
      />
    </a>
    <a target="_blank" :href="androidAppLink" class="login__app-link">
      <img
        src="@/assets/uploads/images/register/google-play.jpg"
        alt="Скачать из Google Play"
      />
    </a>
  </div>

  </div>
</template>

<script>
import PhoneForm from '@/components/pages/auth/PhoneForm.vue'
import SmsForm from '@/components/pages/auth/SmsForm.vue'
import PinForm from '@/components/pages/auth/PinForm.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import { computed, onBeforeMount, ref, watchEffect } from 'vue'
import { useAppInfoStore } from '@/stores/AppInfoStore'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/UserStore'
import { getIsPin } from '../../api/authentication/getIsPin'

export default {
  name: 'LoginPhoneView',
  components: { PhoneForm, SmsForm, PinForm, TheLoader },
  setup () {
    const loading = ref(true)
    const savedPhone = ref('')

    const userStore = useUserStore()
    const appStore = useAppInfoStore()

    const { appInfo } = storeToRefs(appStore)
    const { hasPinCode, userPhone } = storeToRefs(userStore)

    const { setHasPinCode, setUserPhone } = userStore

    const isBrowser = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform !== 'ios' && platform !== 'android'
    })

    const isoAppLink = computed(() => {
      return appInfo.value
        ? appInfo.value.ios_url_web
        : 'https://apps.apple.com/app/id1508496917'
    })

    const androidAppLink = computed(() => {
      return appInfo.value
        ? appInfo.value.android_url_web
        : 'https://play.google.com/store/apps/details?id=com.k31.lk'
    })

    const checkSavedPhone = async () => {
      savedPhone.value = window.localStorage.getItem('user_phone') ?? ''

      if (savedPhone.value) {
        setUserPhone(savedPhone.value)
        try {
          const isPin = await getIsPin(userPhone.value)
          if (isPin && !isBrowser.value) {
            setHasPinCode(true)
          } else {
            setUserPhone(false)
            setHasPinCode(false)
          }
        } catch (error) {
          setHasPinCode(false)
        } finally {
          loading.value = false
        }
      } else {
        loading.value = false
        setHasPinCode(false)
      }
    }

    onBeforeMount(async () => {
      await checkSavedPhone()
    })

    watchEffect(() => {
      // console.log('userPhone', userPhone.value)
      // console.log('hasPinCode', hasPinCode.value)
    })

    return {
      userPhone,
      isBrowser,
      isoAppLink,
      androidAppLink,
      hasPinCode,
      loading,
      savedPhone
    }
  }
}
</script>

<style scoped>
.download {
  display: flex;
  gap: 15px;
  justify-content: center;
  padding-top: 15px;
  margin-top: auto;
}

.login-form-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.login-loader {
  align-self: center;
  justify-self: center;
  flex: 1;
}
</style>
